import { useSupabaseClient, useUser } from '@supabase/auth-helpers-react'
import { useEffect, useState } from 'react'
import { getCreditsBalance } from '@/lib/credits'
import { Database } from '@/types/database.types'

export const useCredits = () => {
  const supabase = useSupabaseClient<Database>()
  const user = useUser()
  const [credits, setCredits] = useState(0)

  useEffect(() => {
    if (!user) return
    const loadCredits = async () => {
      const credits = await getCreditsBalance(supabase, user.id)
      setCredits(credits)
    }
    loadCredits()
  })

  return {
    credits,
  }
}
