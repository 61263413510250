import { SupabaseClient } from '@supabase/supabase-js'
import { Database } from '@/types/database.types'

export const ACTION_TX_COST = {
  client_accept_application: 2,
  client_create_mission: 5,
  client_invite_to_apply: 1,
  freelance_apply_to_offer: 1,
}

export const FREE_CREDITS = {
  freelance: 5,
  client: 15,
}

export const getCreditsBalance = async (
  supabase: SupabaseClient<Database>,
  userId: string
): Promise<number> => {
  const resp = await supabase
    .from('credits_transactions')
    .select('*')
    .or(`from.eq.${userId},to.eq.${userId}`)
  if (resp.error) {
    alert('cannot get credits transactions: ' + resp.error.message)
    return 0
  } else {
    return resp.data!.reduce((partialSum: number, tx: any) => {
      const isDebit = tx.from == userId
      return isDebit ? partialSum - tx.amount : partialSum + tx.amount
    }, 0)
  }
}

export const hasEnoughBalance = async (
  supabase: SupabaseClient<Database>,
  userId: string,
  amount: number
): Promise<boolean> => {
  const balance = await getCreditsBalance(supabase, userId)
  return balance >= amount
}

export const createDebitTransaction = async (
  supabaseAdmin: SupabaseClient<Database>,
  userId: string,
  amount: number,
  data: string
) => {
  const creditsTxResp = await supabaseAdmin.from('credits_transactions').insert({
    from: userId,
    to: null,
    amount: amount,
    data: data,
  })
  if (creditsTxResp.error) {
    throw creditsTxResp.error
  }
  return true
}

export const createCreditTransaction = async (
  supabaseAdmin: SupabaseClient<Database>,
  userId: string,
  amount: number,
  data: string
) => {
  const creditsTxResp = await supabaseAdmin.from('credits_transactions').insert({
    from: null,
    to: userId,
    amount: amount,
    data: data,
  })
  if (creditsTxResp.error) {
    throw creditsTxResp.error
  }
  return true
}
