import { Avatar, Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { useSupabaseClient, useUser } from '@supabase/auth-helpers-react'
import React from 'react'

export default function NavUser() {
  const user = useUser()
  const supabase = useSupabaseClient()
  const clickOnSignOut = () => supabase.auth.signOut()
  return (
    <>
      {user ? (
        <Menu>
          <MenuButton as={Button} rounded={'full'} variant={'link'} cursor={'pointer'} minW={0}>
            <Avatar size="sm" bg="brand.500" />

            {/*<Avatar*/}
            {/*  size={'sm'}*/}
            {/*  src={*/}
            {/*    'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'*/}
            {/*  }*/}
            {/*/>*/}
          </MenuButton>
          <MenuList>
            {/*<MenuItem>Mon compte</MenuItem>*/}
            {/*<MenuDivider />*/}
            <MenuItem onClick={() => clickOnSignOut()}>Se déconnecter</MenuItem>
          </MenuList>
        </Menu>
      ) : null}
    </>
  )
}
