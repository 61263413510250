import { Box, Button, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { routes } from '@/routes'

const Footer = ({ ...props }) => {
  const router = useRouter()
  const clickOnPrivacy = () => router.push(routes.privacy)
  return (
    <Flex bg={'brand.900'} {...props} flexDir={'column'} alignItems={'center'}>
      <Box mx={'auto'} mt={'20'}>
        <Image src="/logo-white.png" width="200" height="100" alt={'logo'} />
      </Box>
      <Text color={'white'} textAlign={'center'} mt={'4'}>
        La 1ère plateforme de freelances de Nouvelle-Calédonie.
      </Text>

      <Flex flexDir={'row'} gap={4}>
        <Button variant={'link'} color={'white'} textAlign={'center'} mt={'10'} mb={'20'}>
          Nous contacter
        </Button>
        <Button
          variant={'link'}
          color={'white'}
          textAlign={'center'}
          mt={'10'}
          mb={'20'}
          onClick={() => clickOnPrivacy()}
        >
          Protection des données
        </Button>
      </Flex>
      <Text color={'whiteAlpha.600'} textAlign={'center'} mt={'10'} mb={'5'}>
        Copyright ©2023 freelances.nc
      </Text>
    </Flex>
  )
}

export default Footer
