export const routes = {
  signUpFreelance: '/inscription-freelance',
  signUpClient: '/inscription-client',
  signIn: '/connexion',
  newMission: '/missions/new',
  editMission: '/missions/edition',
  editFreelanceProfile: '/freelance/edit/profil',
  dashboardFreelance: '/freelance/dashboard',
  dashboardClient: '/client/dashboard',
  freelances: '/freelances',
  searchFreelance: '/recherche/freelance',
  searchMissions: '/recherche/missions',
  profileFreelance: '/freelance/profil',
  profileClient: '/client/profil',
  detailsFreelance: '/freelances',
  missions: '/missions',
  privacy: '/protection-des-donnees',
  credits: '/credits',
  resetPassword: '/reset-password',
  newPassword: '/new-password',
}
