export enum InvitationStatus {
  waiting = 'waiting',
  rejected = 'rejected',
  accepted = 'accepted',
}

export enum MissionStatus {
  waiting = 'waiting',
  rejected = 'rejected',
  accepted = 'accepted',
}

export enum ProfileStatus {
  waiting = 'waiting',
  rejected = 'rejected',
  accepted = 'accepted',
}

export interface MissionVM {
  id: string
  title: string
  level: string
  where: string
  price: number
  when: string
  duration: string
  daysPerWeek: string
  subject: string
  description: string
  workType: string
  createdAt: string
  skills: string[]
}

export interface ClientMissionVM {
  id: string
  title: string
  level: string
  status: string
  where: string
  price: number
  when: string
  daysPerWeek: string
  duration: string
  subject: string
  description: string
  workType: string
  createdAt: string
  skills: string[]
  applications: ClientApplicationVM[]
}

export interface ClientApplicationVM {
  id: string
  freelanceId: string
  freelanceSkills: string[]
  freelancePrice: string
  title: string
  message: string
  date: string
  status: string
  accepted: boolean | null
}

export interface FreelanceVM {
  id: string
  title: string
  level: string
  skills: string[]
  price: string
  available: boolean
}

export interface InvitationVM {
  id: string
  createdAt: string
  missionTitle: string
  missionSkills: string[]
  missionId: string
  missionWorkType: string
  missionDuration: string
}

export interface ApplicationVM {
  id: string
  createdAt: string
  message: string
  missionTitle: string
  missionSkills: string[]
  missionId: string
  missionWorkType: string
  missionDuration: string
}
