import React, { ReactNode } from 'react'
import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  IconButton,
  Link,
  Spacer,
  Stack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons'
import { useRouter } from 'next/router'
import NavUser from '@/components/NavUser'
import Image from 'next/image'
import SignUpModal from '@/components/modal/SignUpModal'
import { useSupabaseClient, useUser } from '@supabase/auth-helpers-react'
import { routes } from '@/routes'
import logo from '../../public/logo.png'
import { strings } from '@/strings'
import { isClient, isFreelance } from '@/utils/utils'
import MiniCredits from '@/components/MiniCredits'

const NavLink = ({ href, children }: { href: string; children: ReactNode }) => (
  <Link
    px={2}
    py={1}
    rounded={'md'}
    _hover={{
      textDecoration: 'none',
      bg: useColorModeValue('gray.200', 'gray.700'),
    }}
    href={href}
  >
    {children}
  </Link>
)

const NavButtonLink = ({ children, onClick }: { children: ReactNode; onClick?: () => void }) => (
  <Button
    variant={'link'}
    px={2}
    py={1}
    rounded={'md'}
    textAlign={'left'}
    justifyContent="flex-start"
    fontWeight={'normal'}
    color={'black'}
    _hover={{
      textDecoration: 'none',
      bg: useColorModeValue('gray.200', 'gray.700'),
    }}
    onClick={() => onClick?.()}
  >
    {children}
  </Button>
)

export default function Nav() {
  const menu = useDisclosure()
  const modal = useDisclosure()
  const router = useRouter()
  const user = useUser()
  const supabase = useSupabaseClient()

  const clickOnIamFreelance = () => {
    router.push(routes.freelances)
    // menu.onClose()
  }
  const clickOnSignIn = () => {
    router.push(routes.signIn)
    menu.onClose()
  }
  const clickOnSignUp = () => {
    modal.onOpen()
    menu.onClose()
  }
  const clickOnSearchFreelance = () => {
    router.push(routes.searchFreelance)
    menu.onClose()
  }
  const clickOnSearchMission = () => {
    router.push(routes.searchMissions)
    menu.onClose()
  }
  const clickOnDashboard = () => {
    menu.onClose()
    if (user) {
      if (isFreelance(user)) {
        router.push(routes.dashboardFreelance)
      } else if (isClient(user)) {
        router.push(routes.dashboardClient)
      }
    }
  }
  const clickOnSignOut = () => supabase.auth.signOut()

  const isClientUser = user ? isClient(user) : false
  const isFreelanceUser = user ? isFreelance(user) : false

  return (
    <>
      <SignUpModal isOpen={modal.isOpen} onClose={modal.onClose} onOpen={modal.onOpen} />
      <Box px={{ base: 0, md: 5 }} borderBottom="1px" borderColor="#e0ded8" bg={'white'} py={2}>
        <Container maxW={'container.xl'}>
          <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
            <IconButton
              size={'md'}
              icon={menu.isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={'Open Menu'}
              display={{ md: 'none' }}
              mr={2}
              onClick={menu.isOpen ? menu.onClose : menu.onOpen}
            />
            <HStack spacing={8} alignItems={'center'}>
              <Box mr={4}>
                <Link href="/">
                  <Box maxW={'240px'}>
                    <Image src={logo} alt={'logo'} />
                  </Box>
                </Link>
              </Box>
              {/*<HStack*/}
              {/*  as={'nav'}*/}
              {/*  spacing={4}*/}
              {/*  display={{base: 'none', md: 'flex'}}>*/}
              {/*  {Links.map((link) => (*/}
              {/*    <NavLink key={link}>{link}</NavLink>*/}
              {/*  ))}*/}
              {/*</HStack>*/}
            </HStack>
            <Spacer />
            <Flex alignItems={'center'} flexDir={'row'} display={{ base: 'none', md: 'initial' }}>
              {!isClientUser && !isFreelanceUser ? (
                <Button
                  size={'md'}
                  variant={'outline'}
                  colorScheme={'black'}
                  display={{ base: 'none', md: 'initial' }}
                  mr={4}
                  onClick={() => clickOnIamFreelance()}
                >
                  {strings.nav_freelances_page_button}
                </Button>
              ) : null}
              {!user ? (
                <>
                  <Button
                    variant={'link'}
                    colorScheme={'brand'}
                    mr={4}
                    fontWeight={'bold'}
                    onClick={() => clickOnSignIn()}
                  >
                    {strings.nav_signin_button}
                  </Button>
                  <Button
                    variant={'solid'}
                    colorScheme={'brand'}
                    mr={4}
                    onClick={() => clickOnSignUp()}
                  >
                    {strings.nav_signup_button}
                  </Button>
                </>
              ) : (
                <>
                  {isClientUser ? (
                    <Button
                      variant={'outline'}
                      colorScheme={'brand'}
                      mr={4}
                      onClick={() => clickOnSearchFreelance()}
                    >
                      {strings.nav_find_freelance}
                    </Button>
                  ) : null}
                  {isFreelanceUser ? (
                    <Button
                      variant={'outline'}
                      colorScheme={'brand'}
                      mr={4}
                      onClick={() => clickOnSearchMission()}
                    >
                      {strings.nav_find_mission}
                    </Button>
                  ) : null}
                  <Button
                    variant={'solid'}
                    colorScheme={'brand'}
                    mr={4}
                    onClick={() => clickOnDashboard()}
                  >
                    {strings.nav_dashboard_button}
                  </Button>
                </>
              )}
            </Flex>
            {user ? (
              <Box mr={'4'}>
                <MiniCredits />
              </Box>
            ) : null}
            <Box display={{ base: 'none', md: 'initial' }}>
              <NavUser />
            </Box>
          </Flex>
          {menu.isOpen ? (
            <Box pb={4} display={{ md: 'none' }}>
              <Stack as={'nav'} spacing={4}>
                <NavLink href={'/freelances'}>Je suis Freelance</NavLink>
                {!user ? (
                  <>
                    <NavButtonLink onClick={() => clickOnSearchFreelance()}>
                      Trouver un freelance
                    </NavButtonLink>
                    <NavButtonLink onClick={() => clickOnSearchMission()}>
                      Trouver une mission
                    </NavButtonLink>
                    <NavButtonLink onClick={() => clickOnSignIn()}>
                      {strings.nav_signin_button}
                    </NavButtonLink>
                    <NavButtonLink onClick={() => clickOnSignUp()}>
                      {strings.nav_signup_button}
                    </NavButtonLink>
                  </>
                ) : (
                  <>
                    {isClientUser ? (
                      <NavButtonLink onClick={() => clickOnSearchFreelance()}>
                        Trouver un freelance
                      </NavButtonLink>
                    ) : null}
                    {isFreelanceUser ? (
                      <NavButtonLink onClick={() => clickOnSearchMission()}>
                        Trouver une mission
                      </NavButtonLink>
                    ) : null}
                    <NavButtonLink onClick={() => clickOnDashboard()}>
                      {strings.nav_dashboard_button}
                    </NavButtonLink>
                    <NavButtonLink onClick={() => clickOnSignOut()}>Se déconnecter</NavButtonLink>
                  </>
                )}
              </Stack>
            </Box>
          ) : null}
        </Container>
      </Box>
    </>
  )
}
