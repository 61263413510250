import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { useRouter } from 'next/router'
import { BiBuilding, BiUser } from 'react-icons/bi'
import BigButton from '../BigButton'
import { routes } from '@/routes'

export default function SignUpModal({
  isOpen,
  onOpen,
  onClose,
}: {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}) {
  const router = useRouter()
  const clickOnSignUpFreelance = () => {
    router.push(routes.signUpFreelance)
    onClose()
  }
  const clickOnSignUpClient = () => {
    router.push(routes.signUpClient)
    onClose()
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
      <ModalOverlay bg="none" backdropFilter="auto" backdropInvert="30%" backdropBlur="2px" />
      <ModalContent>
        <ModalHeader>Quel est votre situation ?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text></Text>
          <Flex flexDir="column" gap="6" mt={10}>
            <BigButton
              title={'Client / Entreprise'}
              subtitle={'Je cherche un freelance'}
              leftIcon={<BiBuilding size={'40'} />}
              onClick={() => clickOnSignUpClient()}
            />
            <BigButton
              title={'Freelance'}
              subtitle={'Je cherche une mission'}
              leftIcon={<BiUser size={'40'} />}
              onClick={() => clickOnSignUpFreelance()}
            />
          </Flex>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  )
}
