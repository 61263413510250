import { createBrowserSupabaseClient } from '@supabase/auth-helpers-nextjs'
import { SessionContextProvider } from '@supabase/auth-helpers-react'
import React, { useEffect, useState } from 'react'
import { CacheProvider, EmotionCache } from '@emotion/react'
import { ChakraProvider } from '@chakra-ui/react'
import createEmotionCache from '../../config/createEmotionCache'
import theme from '@/utils/theme'
import 'instantsearch.css/themes/satellite-min.css'
import Layout from '@/components/layout'
import { useRouter } from 'next/router'
import { useCookies } from 'react-cookie'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

export const appUrl = process.env.NEXT_PUBLIC_APP_URL
if (!appUrl) throw 'NEXT_PUBLIC_APP_URL is missing'

export const isProd = process.env.NODE_ENV === 'production'

function MyApp(props: { Component: any; emotionCache?: EmotionCache | undefined; pageProps: any }) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props
  const [supabaseClient] = useState(() => createBrowserSupabaseClient())

  const [cookies] = useCookies(['cookie-policy'])

  // track page view for google analytics
  const router = useRouter()
  useEffect(() => {
    if (cookies['cookie-policy'] == 1 && isProd) {
      // @ts-ignore
      const handleRouteChange = (url) => {
        // @ts-ignore
        window.gtag('config', process.env.NEXT_PUBLIC_GA_ID, {
          page_path: url,
        })
      }
      router.events.on('routeChangeComplete', handleRouteChange)
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange)
      }
    }
  }, [router.events, cookies])

  return (
    <ChakraProvider theme={theme}>
      <CacheProvider value={emotionCache}>
        <SessionContextProvider
          supabaseClient={supabaseClient}
          initialSession={pageProps.initialSession}
        >
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </SessionContextProvider>
      </CacheProvider>
    </ChakraProvider>
  )
}

export default MyApp
