import { Button, Flex, Text } from '@chakra-ui/react'
import React, { ReactElement } from 'react'

export default function BigButton({
  onClick,
  leftIcon,
  title,
  subtitle,
}: {
  onClick: () => void
  leftIcon: ReactElement
  title: string
  subtitle?: string
}) {
  return (
    <Button
      w={'full'}
      justifyContent="flex-start"
      variant={'outline'}
      leftIcon={leftIcon}
      onClick={() => onClick()}
      py={10}
      borderRadius={10}
    >
      <Flex flexDir={'column'} justifyContent="flex-start" ml={5} textAlign={'left'}>
        <Text fontSize={'xl'}>{title}</Text>
        <Text color={'gray'} fontWeight={'normal'}>
          {subtitle}
        </Text>
      </Flex>
    </Button>
  )
}
