import Nav from '@/components/Nav'
import Footer from '@/components/home/Footer'
import CookiesBanner from '@/components/CookiesBanner'
import React from 'react'
import Head from 'next/head'

// @ts-ignore
export default function Layout({ children }) {
  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta name="keywords" content="freelances.nc, freelances, nouvelle-calédonie, freelance" />
        <meta name="author" content="STARTAPP" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <Nav />
      <main>{children}</main>
      <Footer />
      <CookiesBanner />
    </>
  )
}
