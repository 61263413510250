import React from 'react'
import { Button, HStack, Text } from '@chakra-ui/react'
import { useUser } from '@supabase/auth-helpers-react'
import { useRouter } from 'next/router'
import { routes } from '@/routes'
import { isFreelance } from '@/utils/utils'
import { useCredits } from '@/hooks/useCredits'

export default function MiniCredits() {
  const user = useUser()
  const router = useRouter()
  const { credits } = useCredits()

  const clickOnCard = () => {
    if (isFreelance(user!)) router.push(routes.dashboardFreelance)
    else router.push(routes.dashboardClient)
  }

  return (
    <>
      <Button variant={'outline'} onClick={() => clickOnCard()} p={1} borderRadius={5}>
        <HStack spacing={0} m={2}>
          <Text fontSize={'10px'} fontWeight={'bold'}>
            CRÉDITS:{' '}
          </Text>
          <Text fontWeight={'bold'} pl={1}>
            {credits}
          </Text>
        </HStack>
      </Button>
    </>
  )
}
