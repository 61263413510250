import React, { useEffect, useState } from 'react'
import { Box, Button, Center, Flex, Link, Spacer, Text } from '@chakra-ui/react'
import { useCookies } from 'react-cookie'
import { routes } from '@/routes'

export default function CookiesBanner() {
  const [cookies, setCookie] = useCookies(['cookie-policy'])
  const [show, setShow] = useState(true)

  const clickOnAccept = () => {
    setCookie('cookie-policy', 1)
    window.location.reload()
  }

  useEffect(() => {
    if (cookies['cookie-policy'] == 1) setShow(false)
  }, [cookies])

  if (!show) return null

  return (
    <Box
      bg={'gray.100'}
      width={'full'}
      position={'fixed'}
      bottom={0}
      px={{ base: 4, md: 10 }}
      py={4}
      alignContent={'center'}
    >
      <Flex flexDir={{ base: 'column', md: 'row' }} gap={2}>
        <Center>
          <Text fontSize={{ base: '10px', md: '13px' }}>
            Ce site Web utilise des cookies afin de vous fournir les fonctionnalités prévues et une
            expérience personnalisée. En cliquant sur Accepter ou en naviguant sur ce site Web, vous
            acceptez nos <Link href={routes.privacy}>Politique de cookies</Link>.
          </Text>
        </Center>
        <Spacer />
        <Button size={'xs'} variant={'solid'} colorScheme={'brand'} onClick={() => clickOnAccept()}>
          Accepter
        </Button>
      </Flex>
    </Box>
  )
}
