import { AuthUser } from '@supabase/supabase-js'
import { auth } from '@/constants'
import { CreateToastFnReturn } from '@chakra-ui/react'
import { MissionStatus } from '@/types/viewmodels.types'

export const isFreelance = (user: AuthUser) => {
  return user.user_metadata.userType === auth.type.freelance
}

export const isClient = (user: AuthUser) => {
  return user.user_metadata.userType === auth.type.client
}

export const getLevelString = (level: string | null): string | null => {
  if (level == 'expert') return 'Expert (7 ans et +)'
  if (level == 'intermediate') return 'Intermédiaire (2-7ans)'
  if (level == 'junior') return 'Junior (0-2ans)'
  return null
}

export const getWorkType = (workType: string): string | null => {
  let text = null
  if (workType == 'remote') {
    text = 'À distance'
  } else if (workType == 'hybrid') {
    text = 'À distance ou sur place'
  } else if (workType == 'onsite') {
    text = 'Sur place'
  }
  return text
}

export const getDurationString = (duration: string): string | null => {
  let text = null
  if (duration == 's') {
    text = "moins d'1 mois"
  } else if (duration == 'm') {
    text = 'entre 1 et 3 mois'
  } else if (duration == 'l') {
    text = 'entre 3 et 6 mois'
  } else if (duration == 'xl') {
    text = 'plus de 6 mois'
  }
  return text
}

export const getDaysPerWeekString = (days: number): string | null => {
  let text
  if (days == 1) {
    text = '1 jour / semaine'
  } else if (days == 0) {
    text = 'Je ne sais pas'
  } else {
    text = `${days} jours / semaine`
  }
  return text
}

export const getMissionStatusString = (duration: string): string | null => {
  let text = null
  if (duration == MissionStatus.waiting) {
    text = 'En attente de validation'
  } else if (duration == MissionStatus.accepted) {
    text = 'Validé'
  } else if (duration == MissionStatus.rejected) {
    text = 'Rejeté'
  }
  return text
}

export function toastError<Result, Err>(
  toast: CreateToastFnReturn,
  title: string,
  description: string
) {
  toast({
    title: title,
    position: 'top-right',
    description: description,
    status: 'error',
    duration: 5000,
    isClosable: true,
  })
}

export function toastSuccess<Result, Err>(
  toast: CreateToastFnReturn,
  title: string,
  description: string
) {
  toast({
    title: title,
    description: description,
    status: 'success',
    duration: 5000,
    isClosable: true,
  })
}
