import { ACTION_TX_COST } from '@/lib/credits'

export const strings = {
  general: {
    emailLabel: 'Email',
    passwordLabel: 'Mot de passe',
  },
  nav_freelances_page_button: 'Je suis Freelance',
  nav_dashboard_button: 'Mon Dashboard',
  nav_find_freelance: 'Trouver un freelance',
  nav_find_mission: 'Trouver une mission',
  client_form_level_label: "Nombre d'année d'expériences",
  client_form_what_step_label: "Quel est l'objet de votre mission ?",
  client_form_what_step_help_text: "C'est ce que le freelance verra en premier.",
  nav_signin_button: 'Se connecter',
  nav_signup_button: "S'inscrire",
  signup: {
    title: 'Créer mon compte',
    emailLabel: 'Email',
    linkedInButton: "S'inscrire avec LinkedIn",
    cguValidation:
      'En cliquant sur le bouton S’inscrire, vous acceptez les Termes et conditions d’utilisation du site Internet freelances.nc.',
    errors: {
      accountAlreadyExists: 'Ce compte existe déjà',
    },
  },
  search: {
    freelance: {
      title: 'Trouver un freelance',
    },
  },
  form: {
    skills: {
      label: 'Compétences:',
      help: 'Par exemple: android, design, moa, gestion de projet, scrum, java, javascript, python, web, mobile... (3 min.) Appuyez `Entrée` entre chaque compétence.',
    },
  },
  client: {
    forms: {
      errors: {
        noMissionStartDate: 'Veuillez indiquer la date de début de mission.',
        noDuration: 'Veuillez sélectionner la durée de la mission.',
        noDayPerWeek: 'Veuillez sélectionner le nombre de jours par semaine.',
        noSubject: "Veuillez saisir l'objet de la mission.",
      },
    },
  },
  freelance: {
    slack: {
      title: 'Accéder au canal Slack',
      content:
        'Rejoignez notre communauté de freelances en Nouvelle-Calédonie sur Slack pour partager vos connaissances, trouver du soutien et faire des rencontres !',
      button: 'Rejoindre la communauté',
    },
  },
  modal_error_title: 'Oops...',

  dashboard_create_new_mission_button: `Créer une nouvelle offre (${ACTION_TX_COST.client_create_mission} Crédits)`,

  applications_modal_message_label: 'Votre message:',
  applications_modal_message_placeholder:
    'Entrez le message que vous souhaitez joindre à votre candidature.',
  applications_modal_helper_txt:
    'Montrez pourquoi vous êtes le candidat idéal. Ne mentionnez pas votre contact (prénom,nom,société,téléphone...) dans le message.',
  applications_modal_success_title: 'Candidature envoyée !',
  applications_modal_success_message: 'Votre invitation à postuler a été envoyée avec succès !',
  applications_modal_button_send: `Envoyer (${ACTION_TX_COST.freelance_apply_to_offer} Crédits)`,
  applications_empty: 'Pas de candidatures pour le moment',
  applications_title: '💼 Candidatures',
  applications_subtitle: 'La liste des offres de missions auxquelles tu as candidaté.',

  invitations_title: '⚡ Invitations à postuler',
  invitations_empty: "Pas d'invitations pour le moment",
  invitations_subtitle: "Des entreprises t'invitent à postuler à leurs offres de mission.",
  invitations_item_apply_button: 'Postuler',

  modal_no_more_credits_title: "💸😞 Vous n'avez pas assez de crédits !",
  modal_no_more_credits_content:
    "Vous n'avez pas assez de crédits pour effectuer cette action. Vous pouvez faire une demande crédits en cliquant sur le bouton 'Obtenir des crédits'.",
  modal_no_more_credits_button: 'Obtenir des crédits',

  modal_get_credits_title: '💰 Demande de crédits',
  modal_get_credits_content:
    "Pour confirmer votre demande de crédits, veuillez cliquer sur 'Confirmer' et nous reviendrons vers vous au plus vite.",
  modal_get_credits_button: 'Confirmer',

  modal_invite_to_apply_button: `Envoyer (${ACTION_TX_COST.client_invite_to_apply} Crédits)`,
  error_toast_missing_mission_title: 'Mission manquante',
  error_toast_missing_mission_content:
    'Vous devez sélectionner une mission pour inviter un freelance.',
  toast_invitation_sent_title: 'Invitation envoyée !',
  toast_invitation_sent_content: 'Votre invitation à postuler a été envoyée avec succès !',

  dashboard_freelance_free_credits_text:
    'Nous vous offrons vos premiers crédits pour candidater aux offres de missions !',
  dashboard_client_free_credits_text:
    'Nous vous offrons vos premiers crédits pour créer vos offres de missions !',

  client_missions_empty_content:
    "Cliquez sur 'Créer une nouvelle offre' pour créer votre première offre de mission.",
  client_missions_empty_title: 'Aucune missions pour le moment',

  client_freelances_empty_title: 'Aucun profils pour le moment',

  reset_password_title: 'Réinitialiser votre mot de passe',
  reset_password_button: 'Envoyer',
  new_password_button: 'Valider',
  new_password_title: 'Nouveau mot de passe',
}
