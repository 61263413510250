import { extendTheme } from '@chakra-ui/react'
import { Raleway } from 'next/font/google'

// const poppins = Poppins({
//   weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
//   style: ['normal', 'italic'],
//   subsets: ['latin'],
// })
//
// const inter = Inter({
//   weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
//   style: ['normal'],
//   subsets: ['latin'],
// })

const raleway = Raleway({
  weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
  style: ['normal'],
  subsets: ['latin'],
})

// const roboto = Roboto({
//   weight: ['400', '700', '300', '900', '500'],
//   style: ['normal', 'italic'],
//   subsets: ['latin'],
// })

const overrides = {
  fonts: {
    body: raleway.style.fontFamily,
    heading: raleway.style.fontFamily,
  },
  colors: {
    brand: {
      100: '#D3D6FB',
      200: '#A8ADF8',
      300: '#7980EC',
      400: '#555DD9',
      500: '#252DC0',
      600: '#1B21A5',
      700: '#12178A',
      800: '#0B0F6F',
      900: '#07095C',
    },
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: '100px',
      },
      sizes: {
        small: {
          // px: '40px',
          // h: '50px',
          // fontSize: '20px',
          // fontSize: '32px',
        },
        medium: {
          // px: '40px',
          // h: '60px',
          fontSize: '40px',
        },
        large: {
          // px: 8,
          // h: '70px',
          // fontSize: '30px',
          // borderRadius: '10px',
        },
      },
      // variants: {
      //   primary: {
      //     bg: 'primary',
      //     color: '#fff',
      //   },
      //   secondary: {
      //     bg: 'secondary',
      //     color: '#fff',
      //   },
      //   ghost: {
      //     bg: 'transparent',
      //     border: '1px solid red',
      //   },
      //   primaryGhost: {
      //     bg: 'transparent',
      //     border: '1px solid',
      //     borderColor: 'primary',
      //   },
      //   secondaryGhost: {
      //     bg: 'transparent',
      //     border: '1px solid',
      //     borderColor: 'secondary',
      //     _hover: {
      //       color: '#fff',
      //       bg: '#BB1415',
      //     },
      //   },
      // },
    },

    // Heading: {
    //   baseStyle: {
    //     fontFamily: "Inter",
    //     fontWeight: "600",
    //   },
    //   sizes: {
    //     small: {
    //       fontSize: "20px",
    //     },
    //     medium: { fontSize: "25px" },
    //     large: { fontSize: "30px" },
    //   },
    // },
  },
}

const theme = extendTheme(overrides)
export default theme
